import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';

const ProjectDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [project, setProject] = useState(null);
  const currentProjectData = location.state.project;

  useEffect(() => {
    const fetchProjectDetails = async (currentProjectData) => {
      try {
        const ProjectNameDeleteSpace = currentProjectData.name.replace(/\s/g, '')
        const response = await axios.post('https://graphigo.prd.galaxy.eco/query', {
          "operationName": "ProjectDetail",
          "variables": {
              "name":ProjectNameDeleteSpace,
              "galxeId": ""
          },
          "query": `query ProjectDetail($name: String, $galxeId: String!) {
        isAdmin
        project(name: $name) {
          id
          name
          isPrivate
          seoImage
          alias
          description
          recommendations
          thumbnail
          tags
          links
          fundraise
          updatedAt
          createdAt
          chains
          investors
          officialTasks: tasks(input: {first: 10, after: "-1", isOfficial: true, galxeId: $galxeId}) {
            list {
              id
              projectId
              isPrivate
              title {
                en
                zh
                __typename
              }
              link
              estimatedTime
              estimatedCost
              reward
              completed
              taskPeriod
              createdAt
              updatedAt
              steps {
                id
                taskId
                orderNum
                title {
                  zh
                  en
                  __typename
                }
                description {
                  zh
                  en
                  __typename
                }
                comments {
                  zh
                  en
                  __typename
                }
                links
                contractData {
                  type
                  interactionName
                  depositData {
                    chains {
                      name
                      explorerUrl
                      symbol
                      chainId
                      galxeChain
                      rpcUrl
                      tokens {
                        name
                        contractAddress
                        symbol
                        logoURI
                        address
                        decimals
                        method
                        abi
                        value
                        inputs {
                          type
                          name
                          value
                          __typename
                        }
                        __typename
                      }
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          suggestTasks: tasks(input: {first: 10, after: "-1", isOfficial: false, galxeId: $galxeId}) {
            list {
              id
              projectId
              isPrivate
              title {
                en
                zh
                __typename
              }
              estimatedTime
              estimatedCost
              reward
              link
              completed
              taskPeriod
              createdAt
              updatedAt
              steps {
                id
                taskId
                orderNum
                title {
                  zh
                  en
                  __typename
                }
                description {
                  zh
                  en
                  __typename
                }
                comments {
                  zh
                  en
                  __typename
                }
                links
                contractData {
                  type
                  interactionName
                  depositData {
                    chains {
                      name
                      symbol
                      explorerUrl
                      chainId
                      galxeChain
                      rpcUrl
                      tokens {
                        name
                        contractAddress
                        symbol
                        logoURI
                        address
                        decimals
                        method
                        abi
                        value
                        inputs {
                          type
                          name
                          value
                          __typename
                        }
                        __typename
                      }
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
      }`
      });

        const projectData = response.data.data.project;
        setProject({
          ...projectData,
          investors: JSON.parse(projectData.investors || '[]'),
          links: JSON.parse(projectData.links || '{}'),
        });
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    };

    fetchProjectDetails(currentProjectData);
  }, [id, currentProjectData]);

  if (!project) {
    return (
      <div className="p-6 bg-[#080E29] text-[#F7FDFF] min-h-screen">
        <Link to="/" className="text-[#EEF3F7] mb-6 inline-block underline">&larr; Go to Explorer</Link>
        <div className="flex justify-between mb-6 animate-pulse">
          <div className='flex items-center'>
            <div className="w-16 h-16 bg-slate-700 rounded-full"></div>
            <div className="ml-4">
              <div className="h-5 bg-slate-700 rounded w-1/4"></div>
              <div className="flex space-x-2 mt-2">
                <div className="h-4 bg-slate-700 rounded w-16"></div>
                <div className="h-4 bg-slate-700 rounded w-16"></div>
              </div>
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="w-4 h-4 bg-slate-700 rounded"></div>
            <div className="w-4 h-4 bg-slate-700 rounded"></div>
            <div className="w-4 h-4 bg-slate-700 rounded"></div>
            <div className="w-4 h-4 bg-slate-700 rounded"></div>
            <div className="w-4 h-4 bg-slate-700 rounded"></div>
          </div>
        </div>
        <p className="mb-4 animate-pulse">
          <div className="h-8 bg-slate-700 rounded w-full"></div>
        </p>
        <div className='flex justify-between mb-6 animate-pulse'>
          <div className="bg-slate-700 p-4 rounded-lg w-1/2 h-16"></div>
          <div className="flex h-8 w-40 bg-slate-700 rounded"></div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between mb-6 animate-pulse">
          <div className="flex lg:mr-3 flex-col w-full lg:w-1/2">
            <div className="bg-slate-700 h-96 rounded-lg mb-4"></div>
          </div>
          <div className="flex flex-col w-full lg:w-1/2">
            <div className="bg-slate-700 h-96 rounded-lg mb-4"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-[#080E29] text-[#F7FDFF] min-h-screen">
      <Link to="/" className="text-[#EEF3F7] mb-6 inline-block underline">&larr; Go to Explorer</Link>
      <div className="flex lg:flex-row justify-between mb-6 animate-dissolve-in">
        <div className='flex items-center'>
          <img src={project.thumbnail} alt="logo" className="lg:w-16 lg:h-16 w-12 h-12 rounded-full" />
          <div className="ml-4">
            <h1 className="lg:text-2xl text-xl font-bold">{project.name}</h1>
            <div className="flex space-x-2 mt-2">
              {project.tags.map((tag, index) => (
                <span key={index} className="bg-[#131B62] lg:px-3 lg:py-1 lg:text-base text-sm px-1 py-1 rounded">{tag}</span>
              ))}
            </div>
          </div>
        </div>
        <div className="flex space-x-4 animate-dissolve-in">
          {project.links.twitterLink && (
            <a href={project.links.twitterLink} target="_blank" rel="noopener noreferrer">
              <img src="/icons/twitter.svg" alt="Twitter" className="w-4 h-4" />
            </a>
          )}
          {project.links.spaceLink && (
            <a href={project.links.spaceLink} target="_blank" rel="noopener noreferrer">
              <img src="/icons/space.svg" alt="Space" className="w-4 h-4" />
            </a>
          )}
          {project.links.mediumLink && (
            <a href={project.links.mediumLink} target="_blank" rel="noopener noreferrer">
              <img src="/icons/medium.svg" alt="Medium" className="w-4 h-4" />
            </a>
          )}
          {project.links.discordLink && (
            <a href={project.links.discordLink} target="_blank" rel="noopener noreferrer">
              <img src="/icons/discord.svg" alt="Discord" className="w-4 h-4" />
            </a>
          )}
          {project.links.telegramLink && (
            <a href={project.links.telegramLink} target="_blank" rel="noopener noreferrer">
              <img src="/icons/telegram.svg" alt="Telegram" className="w-4 h-4" />
            </a>
          )}
          {project.links.websiteLink && (
            <a href={project.links.websiteLink} target="_blank" rel="noopener noreferrer">
              <img src="/icons/website.svg" alt="Website" className="w-4 h-4" />
            </a>
          )}
        </div>
      </div>
      <p className="mb-4 text-sm opacity-70 animate-dissolve-in hidden lg:block">{project.description}</p>
      <div className="hidden lg:block">
        <blockquote className="bg-[#131a3b] p-4 rounded-lg w-1/2">
          <p className="mb-2">{project.recommendations}</p>
        </blockquote>
      </div>
      <div className="flex flex-col lg:flex-row justify-between mb-6 animate-dissolve-in">
        <div className="flex flex-col w-full lg:w-1/2">
          <h2 className="lg:text-xl  text-lg font-bold underline">Official Tasks</h2>
          <div className="border-b-2 border-[#090F33] mb-4"></div>
          {project.officialTasks.list.map(task => (
            <div key={task.id} className="bg-[#090F33] p-4 rounded-lg mb-4 animate-dissolve-in">
              <div className='flex justify-between mb-2'>
                <p className="text-small opacity-70">{task.title.en}</p>
                <div className='flex justify-between'>
                  <p className='bg-[#131B62] px-2 py-1 rounded text-xs'><span className='opacity-70'>Est Time</span> {task.estimatedTime}</p>
                  <p className='bg-[#131B62] px-2 py-1 rounded mx-2 text-xs'><span className='opacity-70'>Est Cost</span> {task.estimatedCost}</p>
                  <p className='bg-[#131B62] px-2 py-1 rounded text-xs'><span className='opacity-70'>Reward</span> {task.reward}</p>
                </div>
                <Link to={task.link} target="_blank" rel="noopener noreferrer" className="text-sm opacity-70 hidden lg:block">Source</Link>
              </div>
              {task.steps.map(step => (
                <div key={step.id} className="mt-4 ml-6">
                  <h4 className="text-base font-bold mt-4 mb-2">{step.title.en}</h4>
                  <Markdown>{step.description.en}</Markdown>
                  {Array.isArray(step.links) && step.links.map((link, index) => (
                    <a key={index} href={link} target="_blank" rel="noopener noreferrer" className="block text-blue-500 underline">
                      {link}
                    </a>
                  ))}
                  {step.comments && <p className="mt-2">{step.comments.en}</p>}
                </div>
              ))}
            </div>
          ))}

          
        </div>
        <div className="flex flex-col w-full lg:w-1/2">
                    <h2 className="lg:text-xl  text-lg font-bold underline">Suggested Tasks</h2>
                    <div className="border-b-2 border-[#090F33] mb-4"></div>
                    {project.suggestTasks.list.map(task => (
                        <div key={task.id} className="bg-[#090F33] p-4 rounded-lg mb-4 animate-dissolve-in">
                            <div className='flex justify-between mb-2'>
                                <p className="text-small opacity-70">{task.title.en}</p>
                                <div className='flex justify-between'>
                                    <p className='bg-[#131B62] px-2 py-1 rounded text-xs'><span className='opacity-70'>Est Time</span> {task.estimatedTime}</p>
                                    <p className='bg-[#131B62] px-2 py-1 rounded mx-2 text-xs'><span className='opacity-70'>Est Cost</span> {task.estimatedCost}</p>
                                    <p className='bg-[#131B62] px-2 py-1 rounded text-xs'><span className='opacity-70'>Reward</span> {task.reward}</p>
                                </div>
                                <Link to={task.link} target="_blank" rel="noopener noreferrer" className="text-sm opacity-70 hidden lg:block">Source</Link>
                            </div>
                            {task.steps.map(step => (
                                <div key={step.id} className="mt-4 ml-6">
                                    <h4 className="lg:text-base text-xs	 font-bold mt-4 mb-2">{step.title.en}</h4>
                                    <Markdown>{step.description.en}</Markdown>
                                    {Array.isArray(step.links) && step.links.map((link, index) => (
                                        <a key={index} href={link} target="_blank" rel="noopener noreferrer" className="block text-blue-500 underline">
                                            {link}
                                        </a>
                                    ))}
                                    {step.comments && <p className="mt-2">{step.comments.en}</p>}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;

