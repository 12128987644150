import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import ProjectDetails from './ProjectDetails';

const App = () => {
  const [projects, setProjects] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [loading,setLoading ] = useState(true)

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.post('https://graphigo.prd.galaxy.eco/query', {
          operationName: "ProjectList",
          variables: {
            listProjectInput: {
              first: 1000,
              after: -1,
              hasOfficialTasks: false,
              fundraise: null,
              investors: null,
              chains: null,
              tags: null,
              listType: "Newest"
            }
          },
          query: `query ProjectList($listProjectInput: ListProjectInput!) {
            isAdmin
            projects(input: $listProjectInput) {
              id
              name
              thumbnail
              galxeSpaceLink
              fundraise
              investors
              tags
              links
              alias
              hasOfficialTasks
              __typename
            }
          }`
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
          }
        });

        const projectsData = response.data.data.projects.map(project => ({
          ...project,
          investors: JSON.parse(project.investors || '[]'),
          links: JSON.parse(project.links || '{}'),
        }));

        setProjects(projectsData);
        setSortedProjects(projectsData);
        setLoading(false)

      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const sortHighToLow = () => {
    const sorted = [...sortedProjects].sort((a, b) => b.fundraise - a.fundraise);
    setSortedProjects(sorted);
  };

  const sortLowToHigh = () => {
    const sorted = [...sortedProjects].sort((a, b) => a.fundraise - b.fundraise);
    setSortedProjects(sorted);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    console.log(Router,Link,projects)
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleProjectClick = (project) => {
    navigate(`/project/${project.id}`, { state: { project } });
  };

  return (

      <Routes>
        <Route path="/" element={
        <>
        {  !!loading && <div className="bg-[#080E29] min-h-screen">
        <div class="flex justify-center items-center h-screen">
  <div class="rounded-full h-5 w-5 bg-slate-900 animate-ping"></div>
  <div class="rounded-full h-5 w-5 bg-slate-700 animate-ping mx-12"></div>
  <div class="rounded-full h-5 w-5 bg-slate-500 animate-ping"></div>
  <div class="rounded-full h-5 w-5 bg-slate-700 animate-ping  mx-12"></div>
  <div class="rounded-full h-5 w-5 bg-slate-900 animate-ping"></div>

</div>

          </div>}
        {!loading &&          <div className="lg:p-6 p-2 bg-[#080E29] text-[#F7FDFF] min-h-screen">
          <table className="w-full text-left">
            <thead>
              <tr className="animate-dissolve-in text-[#9097a6] border-b border-[#080E29]">
                <th className="py-3">Project</th>
                <th className="py-3">Backed By</th>
                <th className="py-3 text-right lg:text-justify">
                  <div>
                    <button
                      type="button"
                      onClick={toggleDropdown}
                      className="inline-flex justify-center w-full border-none shadow-sm px-4 py-2 text-sm font-bold text-[#9097a6] border-none  focus:text-white"
                      id="menu-button"
                      aria-expanded={isOpen}
                      aria-haspopup="true"
                    >
                      Funding
                      <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.293l3.71-4.063a.75.75 0 011.13.98l-4 4.39a.75.75 0 01-1.13 0l-4-4.39a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>

                  {isOpen && (
                    <div className="text-[#EEF3F7] bg-[#080E29] bg-opacity-25 border border-[#091f81] origin-top-right absolute right-50 mt-2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                      <div className="py-1" role="none">
                        <button onClick={() => { sortHighToLow(); closeDropdown(); }} className="border-b border-[#091f81] block px-2 py-2 text-sm" role="menuitem" tabIndex="-1">High to Low</button>
                        <button onClick={() => { sortLowToHigh(); closeDropdown(); }} className="block px-2 py-2 text-sm" role="menuitem" tabIndex="-1">Low to High</button>
                      </div>
                    </div>
                  )}
                </th>
                <th className="py-3 hidden lg:table-cell">Tags</th>
                <th className="py-3 hidden lg:table-cell">Official Tasks</th>
                <th className="py-3 hidden lg:table-cell">Links</th>
              </tr>
            </thead>

            <tbody>
              {/* loading here  */} 
              {/* to create movtion */}
              {sortedProjects.map((project) => (

                <tr key={project.id} className="cursor-pointer border-b border-[#131a3b] animate-dissolve-in"  >

                  <td className="py-4 flex items-center space-x-2" onClick={() => handleProjectClick(project)}>
                    <img src={project.thumbnail} alt="logo" className="w-8 h-8 rounded-full" />
                    <span className="text-[#F7FDFF] font-semibold lg:font-bold text-sm lg:text-base">{project.name}</span>
                  </td>
                  <td className="py-4">
                    <div className="flex space-x-2">
                      {Array.isArray(project.investors) && project.investors.length > 0 ? (
                        <>
                          {project.investors.slice(0, 3).map((investor, index) => (
                            <img
                              key={index}
                              src={investor.icon}
                              alt={investor.displayName}
                              className="w-6 h-6 rounded-full sm:hidden"
                            />
                          ))}
                          {project.investors.slice(0, 5).map((investor, index) => (
                            <img
                              key={index}
                              src={investor.icon}
                              alt={investor.displayName}
                              className="w-6 h-6 rounded-full hidden sm:block"
                            />
                          ))}
                        </>
                      ) : (
                        <span className="text-[#F7FDFF]">No investors listed</span>
                      )}
                      {Array.isArray(project.investors) && project.investors.length > 5 && (
                        <span className="w-6 h-6 rounded-full bg-[#080E29] text-[#F7FDFF] flex items-center justify-center">
                          +{project.investors.length - 5}
                        </span>
                      )}
                    </div>
                  </td>

                  <td className="py-4 font-semibold text-right lg:text-justify pr-2 lg:pr-0 lg:font-bold text-sm lg:text-base text-[#EEF3F7]">
                    {project.fundraise / 1000000 === 0 ? '-' : `$${(project.fundraise / 1000000).toFixed(1)}M`}
                  </td>
                  <td className="py-4 hidden lg:table-cell">
                    <div className="flex space-x-2">
                      {Array.isArray(project.tags) ? (
                        project.tags.map((tag, index) => (
                          <span key={index} className="bg-[#131B62] text-[#F7FDFF] px-3 py-1 rounded">{tag}</span>
                        ))
                      ) : (
                        <span className="text-[#F7FDFF]">No tags listed</span>
                      )}
                    </div>
                  </td>
                  <td className="py-4 text-center hidden lg:table-cell">
                    {project.hasOfficialTasks ? (
                      <img src="/icons/checkmark-icon.png" alt="Official Tasks" className="w-6 h-6 mx-auto" />
                    ) : (
                      <img src="/icons/cross-icon.png" alt="No Official Tasks" className="w-6 h-6 mx-auto" />
                    )}
                  </td>
                  <td className="py-4 hidden lg:table-cell">
                    <div className="flex space-x-2">
                      {project.links.twitterLink && (
                        <a href={project.links.twitterLink} target="_blank" rel="noopener noreferrer">
                          <img src="/icons/twitter.svg" alt="Twitter" className="w-4 h-4" />
                        </a>
                      )}
                      {project.links.spaceLink && (
                        <a href={project.links.spaceLink} target="_blank" rel="noopener noreferrer">
                          <img src="/icons/space.svg" alt="Space" className="w-4 h-4" />
                        </a>
                      )}
                      {project.links.mediumLink && (
                        <a href={project.links.mediumLink} target="_blank" rel="noopener noreferrer">
                          <img src="/icons/medium.svg" alt="Medium" className="w-4 h-4" />
                        </a>
                      )}
                      {project.links.discordLink && (
                        <a href={project.links.discordLink} target="_blank" rel="noopener noreferrer">
                          <img src="/icons/discord.svg" alt="Discord" className="w-4 h-4" />
                        </a>
                      )}
                      {project.links.telegramLink && (
                        <a href={project.links.telegramLink} target="_blank" rel="noopener noreferrer">
                          <img src="/icons/telegram.svg" alt="Telegram" className="w-4 h-4" />
                        </a>
                      )}
                      {project.links.websiteLink && (
                        <a href={project.links.websiteLink} target="_blank" rel="noopener noreferrer">
                          <img src="/icons/website.svg" alt="Website" className="w-4 h-4" />
                        </a>
                      )}
                    </div>
                  </td>
                </tr>
              
              ))}
            </tbody>
          </table>
        </div> }

        </>
        } />
        <Route path="/project/:id" element={<ProjectDetails />} />
      </Routes>

  );
};

export default App;
